  #header {
    background-color: #ffffff;
    margin: 0px !important;
    color: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: solid 2px  #1C3685; 
  }

  .buttonDropdown {
    font-weight: 500;
    background: transparent;
    color: #000000;
    border: solid 1px transparent;  
  }

  .buttonDropdownItem {
    background: transparent;
    color: #000000;
    border: solid 1px transparent; 
    margin-top: 20px; 
    padding-left: 5px;
  }

  .userInfo {
    float: right;
  }

  .dropdown {
    position: relative;
    background: transparent;
    float: right;
    background: white;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    color: white;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown-content a {
    display: block;
    text-decoration: none;
  }
  .dropdown-content a:hover {
    color: #000000;
  }
  .common-header-link {
    color: #000000;
    font-weight: 500;
    margin-left: 10px;
  }

  .common-header-link:hover {
    color: #000000;
  }