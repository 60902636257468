#textareaDescription {
    resize: none;
}

#projectSummaryPage {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    margin-bottom: 5%;
}

#inputName {
    width: 100%;
}

.inputWidth {
    width: 100%;
}

.formLabel {
    width: 100%;
    pointer-events: none;
}

input, textarea, select, .react-datepicker-popper {
    pointer-events: auto !important;
}
.formItem {
    pointer-events: auto;
}

label, input {
    display: block; 
}

#dates {
    display: flex; 
    flex-direction: row;
}  

.requiredField {
    display: inline;
    color: red;
}

#checkboxShowRange, #labelRangeCheckbox {
    display: inline;
}

#checkboxShowRange {
    margin-right: 5px;
}