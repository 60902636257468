.commonPageMargin {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.btnDisabled {
    background-color: rgb(211, 211, 211); 
    color: #3a3a3a; 
    border: solid 1px  #3a3a3a;;  
    border-radius: 2px;
    padding: 5px; 
}

.btnEmphasized {
    background-color: rgb(255, 255, 255); 
    color: #1C3685; 
    border: solid 1px #1C3685;  
    border-radius: 2px;
    padding: 5px; 
}  

.btnSecondary {
    background: #fff;
    border: 1px solid rgb(255, 255, 255); 
    color: #1C3685; 
}