body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#projectSummaryPage {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
  margin-bottom: 5%;
}

table {
  caption-side: top !important;
}

.projectOvrviewTable tr {
  height: 32px;
}
.projectOverviewTable tr:nth-child(even){background-color: #f2f2f2;}
.projectOverviewTable th {
    background-color: white;
    padding: 10px;
    color: rgb(0, 0, 0);
    height: 30px;
}

.projectOverviewTable {
    font-family: Helvetica, sans-serif;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #b9b9b9;
    color: black;
}

caption {
  color: black !important;
}

#buttonNewProject {
  float: right;
}

#buttonProjectDetails {
  float: left;
}

#buttonDeleteProject {
  float: left;
}

.btnWeak {
  background: #fff;
  border: 1px solid rgb(255, 255, 255); 
  color: #1C3685; 
}

.btnEmphasized {
  background-color: rgb(255, 255, 255); 
  color: #1C3685; 
  border: solid 1px #1C3685;  
  border-radius: 2px;
  padding: 5px; 
}

.link {
  text-decoration: none;
  background-color: rgb(255, 255, 255); 
  color: #1C3685 
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

table {
  caption-side: top;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

  #header {
    background-color: #ffffff;
    margin: 0px !important;
    color: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: solid 2px  #1C3685; 
  }

  .buttonDropdown {
    font-weight: 500;
    background: transparent;
    color: #000000;
    border: solid 1px transparent;  
  }

  .buttonDropdownItem {
    background: transparent;
    color: #000000;
    border: solid 1px transparent; 
    margin-top: 20px; 
    padding-left: 5px;
  }

  .userInfo {
    float: right;
  }

  .dropdown {
    position: relative;
    background: transparent;
    float: right;
    background: white;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    color: white;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown-content a {
    display: block;
    text-decoration: none;
  }
  .dropdown-content a:hover {
    color: #000000;
  }
  .common-header-link {
    color: #000000;
    font-weight: 500;
    margin-left: 10px;
  }

  .common-header-link:hover {
    color: #000000;
  }
#textareaDescription {
    resize: none;
}

#projectSummaryPage {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    margin-bottom: 5%;
}

#inputName {
    width: 100%;
}

.inputWidth {
    width: 100%;
}

.formLabel {
    width: 100%;
    pointer-events: none;
}

input, textarea, select, .react-datepicker-popper {
    pointer-events: auto !important;
}
.formItem {
    pointer-events: auto;
}

label, input {
    display: block; 
}

#dates {
    display: flex; 
    flex-direction: row;
}  

.requiredField {
    display: inline;
    color: red;
}

#checkboxShowRange, #labelRangeCheckbox {
    display: inline;
}

#checkboxShowRange {
    margin-right: 5px;
}
  #success {
    height: 40px;
    pointer-events: none;
  }
  
  #pageTitleSuccess {
    margin-bottom: 60px;
  }
.commonPageMargin {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.btnDisabled {
    background-color: rgb(211, 211, 211); 
    color: #3a3a3a; 
    border: solid 1px  #3a3a3a;;  
    border-radius: 2px;
    padding: 5px; 
}

.btnEmphasized {
    background-color: rgb(255, 255, 255); 
    color: #1C3685; 
    border: solid 1px #1C3685;  
    border-radius: 2px;
    padding: 5px; 
}  

.btnSecondary {
    background: #fff;
    border: 1px solid rgb(255, 255, 255); 
    color: #1C3685; 
}
.login-wrapper {
    text-align: center;
}

.label {
    display: inline-block;
}

#inputEmail, #inputPassword  {
    display: inline-block;
}
input {
    display: inline;
}

h1 {
    margin-top: 50px;
}
#btnSignUp {
    margin-left: 50;
    font-size: large;  
}

.landingPageText {
    text-align: center;
}
