.login-wrapper {
    text-align: center;
}

.label {
    display: inline-block;
}

#inputEmail, #inputPassword  {
    display: inline-block;
}