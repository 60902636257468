body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#projectSummaryPage {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
  margin-bottom: 5%;
}

table {
  caption-side: top !important;
}

.projectOvrviewTable tr {
  height: 32px;
}
.projectOverviewTable tr:nth-child(even){background-color: #f2f2f2;}
.projectOverviewTable th {
    background-color: white;
    padding: 10px;
    color: rgb(0, 0, 0);
    height: 30px;
}

.projectOverviewTable {
    font-family: Helvetica, sans-serif;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #b9b9b9;
    color: black;
}

caption {
  color: black !important;
}

#buttonNewProject {
  float: right;
}

#buttonProjectDetails {
  float: left;
}

#buttonDeleteProject {
  float: left;
}

.btnWeak {
  background: #fff;
  border: 1px solid rgb(255, 255, 255); 
  color: #1C3685; 
}

.btnEmphasized {
  background-color: rgb(255, 255, 255); 
  color: #1C3685; 
  border: solid 1px #1C3685;  
  border-radius: 2px;
  padding: 5px; 
}

.link {
  text-decoration: none;
  background-color: rgb(255, 255, 255); 
  color: #1C3685 
}
